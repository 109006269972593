import React from 'react'
import Icon from './'

const Clinic = props => (
  <Icon
    {...{
      fill: '#2165e5',
      ...props
    }}
  >
    <path d="M42,21.13H23.37a1,1,0,0,0-1,1V40.75a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V32.88H33.85a1.46,1.46,0,1,1,0-2.91H43V22.1A1,1,0,0,0,42,21.13Z" />
    <path d="M60.23,21.21A8.58,8.58,0,0,0,56,14.75C48.93,10.42,42,6,35.45,2a6.38,6.38,0,0,0-6.9,0C22,6,15.07,10.45,8,14.75a8.63,8.63,0,0,0-4.21,6.46,165.32,165.32,0,0,0,0,21.58A8.58,8.58,0,0,0,8,49.25C15.07,53.55,22,58,28.55,62a6.38,6.38,0,0,0,6.9,0C42,58,48.93,53.55,56,49.25a8.5,8.5,0,0,0,4.21-6.46A165.32,165.32,0,0,0,60.23,21.21ZM45.9,40.75A3.88,3.88,0,0,1,42,44.63H23.37a3.88,3.88,0,0,1-3.87-3.88V22.1a3.88,3.88,0,0,1,3.87-3.88H42A3.88,3.88,0,0,1,45.9,22.1Z" />
  </Icon>
)

export default Clinic
