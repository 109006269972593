import React from 'react'
import Icon from './'

const Arrow = props => (
  <Icon {...props}>
    <path d="M32,62.55a3.82,3.82,0,0,1-2.71-1.12,3.85,3.85,0,0,1,0-5.45L49.71,35.4H5a3.85,3.85,0,0,1,0-7.7H49.71L29.27,7.11a3.85,3.85,0,1,1,5.46-5.42l27,27.14.12.13h0s0,0,0,0h0a3.43,3.43,0,0,1,.46.62h0v0s0,0,0,0h0s0,0,0,0h0a2.6,2.6,0,0,1,.16.34,4,4,0,0,1,.29,1.42h0v0h0A3.85,3.85,0,0,1,62.52,33h0s0,0,0,0h0s0,0,0,0h0a3.83,3.83,0,0,1-.82,1.2L34.73,61.41A3.82,3.82,0,0,1,32,62.55Z" />
  </Icon>
)

export default Arrow
